"use client";
import { FormError } from "@/components/shared/form-error";
import { FormSuccess } from "@/components/shared/form-success.tsx";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { LoginSchema } from "@/schemas/auth";
import Link from "next/link";
import { useState, useTransition } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { login } from "@/core/reducers/user.reducer";
import { useAppDispatch } from "@/hooks/RTKTypedHooks";
import { setUser } from "@/core/reducers/user.reducer";
import { useAction } from "next-safe-action/hooks";
import { useRouter } from "next/navigation";

export default function LoginForm() {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const [isPending, startTransition] = useTransition();
  const [error, setError] = useState<string | undefined>("");
  const [success, setSuccess] = useState<string | undefined>("");

  const form = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: process.env.NODE_ENV === "development" ? "tgil849@gmail.com" : "",
      password: "",
    },
  });

  const onSubmit = (data: z.infer<typeof LoginSchema>) => {
    setError("");
    setSuccess("");
    startTransition(async () => {
      try {
        // const user = await login(data);
        //dispatch(setUser(user));
        const response = await dispatch(login(data));
        if (login.fulfilled.match(response)) {
          setSuccess(response.payload.message);
        } else if (login.rejected.match(response)) {
          // Todo: handle error type
          setError(response.payload || "Une erreur est survenue");
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name='email'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder='john@doe.com'
                  type='email'
                  //disabled={isPending}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='password'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder='******'
                  type='password'
                  //disabled={isPending}
                />
              </FormControl>
              <Button
                size='sm'
                className='px-0 font-normal'
                variant={"link"}
                asChild
              >
                <Link href='/auth/reset'>Mot de passe oublié?</Link>
              </Button>
              <FormMessage />
            </FormItem>
          )}
        />
        {!isPending && (
          <>
            <FormError message={error!} />
            <FormSuccess message={success!} />
          </>
        )}
        {isPending && (
          <p className='mt-2 text-center'>En cours de connexion...</p>
        )}
        <Button disabled={isPending} className='w-full'>
          Se connecter
        </Button>
      </form>
    </Form>
  );
}
